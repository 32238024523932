<template>
  <div ref="container" class="signContainer">
    <div class="title">报名管理</div>
    <div class="tableContainer">
      <el-button class="add" @click="down">下载表格</el-button>
      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
          >
            <el-table-column
              label="报名时间"
              min-width="190"
              prop="createTime"
              align="center"
            />
            <el-table-column
              label="姓名"
              min-width="110"
              prop="name"
              align="center"
            />
            <el-table-column
              label="联系电话"
              min-width="154"
              prop="mobile"
              align="center"
            />
            <el-table-column label="公司" min-width="154" align="center">
              <template slot-scope="scope">
                {{ scope.row.company || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="职业" min-width="154" align="center">
              <template slot-scope="scope">
                {{ scope.row.position || "无" }}
              </template>
            </el-table-column>

            <el-table-column label="人数" min-width="95" align="center">
              <template slot-scope="scope">
                {{ scope.row.number || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="用户提问" min-width="110" align="center">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.question"
                  size="medium"
                  @click="toDetail(scope.row.question)"
                  >查看详情</el-button
                >
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column label="签到状态" min-width="95" align="center">
              <template slot-scope="scope">
                {{ scope.row.status ? "已签到" : "未签到" }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="detail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      title="用户提问"
      width="30%"
    >
      <span style="color: #000; font-size: 14px; line-height: 26px">{{
        detailQuestion
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import EventBus from '@/utils/eventBus'
import { getSignUpActivitiesList } from "@/apis/user";
import { parseTime } from "@/utils/index";
import { getCookie } from "@/utils/auth";
export default {
  data() {
    return {
      tableHeight: "",
      detail: "",
      tableData: [],
      current: 1,
      dialogVisible: false,
      detailQuestion: "",
    };
  },
  activated() {
    this.getSignUpActivitiesList(this.current);
  },
  methods: {
    getSignUpActivitiesList(current = 1) {
      getSignUpActivitiesList({
        current,
        newsId: this.$route.params.newsId,
      }).then((res) => {
        var records = res.result.records;
        records.forEach((item) => {
          item.createTime = parseTime(item.createTime);
        });
        this.tableData = records;
        this.detail = res.result;
      });
    },
    clickPage(e) {
      this.getSignUpActivitiesList(e);
      this.current = e;
    },
    down() {
      var Auth = getCookie("Auth");
      const { newsId } = this.$route.params;
      window.location.href = `https://api.joinzs.com/mpgenerator/gov/downloadSignUp4Csv?newsId=${newsId}&auth=${Auth}`;
    },
    toDetail(question) {
      this.dialogVisible = true;
      this.detailQuestion = question;
    },
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.detailQuestion = "";
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/mixin";
.signContainer {
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 30px;
  }
  .tableContainer {
    overflow: hidden;
    // border: solid 1px #d2d2d2;
    margin: 40px 34px 0;
    box-sizing: border-box;
    .add {
      float: none;
      margin: 12px 0 0 14px;
      @include btnCardConfirm(100px, 34px, 14px);
    }
    .table {
      margin-top: 12px;
    }
  }
  .pagination {
    margin-top: 24px;
  }
}
</style>
